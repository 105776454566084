<template>
  <div class="m-1">
    <div class="mb-2">
      <small>Selecione os dias da semana</small>
    </div>

    <validation-provider
      #default="{ errors }"
      rules="requiredWeek"
    >
      <div v-if="renderComponent">
        <b-button
          v-for="(item, index) in weeks"
          :key="index"
          :variant="color[index]"
          class="btn p-1 rounded-circle btn-sm mr-1"
          @click="select(index)"
        >
          <strong>&nbsp;{{ item.name }}&nbsp;</strong>
        </b-button>
      </div>
      <small class="text-danger mt-2"><br>{{ errors[0] }}</small>
    </validation-provider>

    <div
      v-if="stringWeek.length > 0"
      class="mt-1"
    >
      <small>Tomar medicamento: {{ stringWeek }}</small>
    </div>

  </div>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate'
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from 'vee-validate/dist/rules'

export default {
  components: {
    BButton,

    ValidationProvider,

  },

  directives: {
    Ripple,
  },

  data() {
    return {
      stringWeek: '',
      week: [],
      renderComponent: true,
      color: [],
      weeks: [
        { name: 'D' },
        { name: 'S' },
        { name: 'T' },
        { name: 'Q' },
        { name: 'Q' },
        { name: 'S' },
        { name: 'S' },
      ],
    }
  },

  mounted() {
    extend('requiredWeek', {
      ...required,
      validate: () => {
        if (this.stringWeek !== '') {
          return true
        }
        return 'O campo dias da semana é obrigatório'
      },
    })

    this.week = this.$store.getters['formMedication/frequencyWeek']
    this.stringWeek = this.$store.getters['formMedication/frequencyWeekFormated']
    this.color = this.$store.getters['formMedication/frequencyWeekColor']
  },

  methods: {
    select(value) {
      this.renderComponent = false

      if (this.color[value] === 'outline-primary') {
        this.color[value] = 'primary'
      } else {
        this.color[value] = 'outline-primary'
      }

      const array = []
      this.color.forEach((item, indice) => {
        if (item === 'primary') {
          switch (indice) {
            case 0:
              array.push('dom')
              break
            case 1:
              array.push('seg')
              break
            case 2:
              array.push('ter')
              break
            case 3:
              array.push('qua')
              break
            case 4:
              array.push('qui')
              break
            case 5:
              array.push('sex')
              break
            case 6:
              array.push('sab')
              break
            default:
          }
        }
      })

      const lengthArray = array.length
      let stringWeek = ''

      this.week = array

      array.forEach((valueArray, index) => {
        stringWeek += valueArray
        if (index < lengthArray - 2) {
          stringWeek += ', '
        } else if (index === lengthArray - 2) {
          stringWeek += ' e '
        }
      })

      this.stringWeek = stringWeek

      this.renderComponent = true

      this.send()
    },
    send() {
      this.$store.commit('formMedication/UPDATE_FREQUENCY_INTERVAL', '')
      this.$store.commit('formMedication/UPDATE_FREQUENCY_WEEK', this.week)
      this.$store.commit('formMedication/UPDATE_FREQUENCY_WEEK_FORMATED', this.stringWeek)
      this.$store.commit('formMedication/UPDATE_FREQUENCY_WEEK_COLOR', this.color)
    },
  },
}
</script>
