<template>
  <div class="m-1">
    <validation-observer ref="rules">
      <div class="mb-2">
        <small>Selecione um intervalo</small>
      </div>

      <div v-if="renderComponent">

        <b-button
          v-for="(item, index) in weeks"
          :key="index"
          :variant="color[index]"
          class="btn p-1 rounded-circle btn-md mr-1"
          @click="select(index)"
        >
          <strong>&nbsp;{{ item.name }}&nbsp;</strong>
        </b-button>

        <b-col
          cols="7"
        >
          <div
            v-if="showDay"
            class="mt-2"
          >
            <validation-provider
              #default="{ errors }"
              name="tomar o medicamento a cada quantos dias"
              rules="required"
            >
              <b-form-group
                label="Tomar o medicamento a cada quantos dias?"
                label-for="medication"
              >

                <b-input-group append="dias">
                  <b-form-input
                    v-model="days"
                    type="number"
                    @blur="send()"
                  />
                </b-input-group>

              </b-form-group>
              <small class="text-danger mt-2">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-col>

      </div>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BFormGroup, BFormInput, BInputGroup, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCol,

    ValidationProvider,
    ValidationObserver,

  },

  directives: {
    Ripple,
  },

  data() {
    return {
      days: '',
      renderComponent: true,
      showDay: false,
      color: [],

      weeks: [
        { name: '2' },
        { name: '3' },
        { name: '5' },
        { name: '7' },
        { name: '15' },
        { name: '+' },
      ],
    }
  },

  mounted() {
    this.load()
    this.send()
  },

  methods: {
    load() {
      this.color = this.$store.getters['formMedication/frequencyInternalColor']
      this.days = this.$store.getters['formMedication/frequencyInternal']
      if (this.color[5] === 'primary') {
        this.showDay = true
      }
    },
    select(value) {
      this.renderComponent = false
      this.showDay = false

      this.color = [
        'outline-primary',
        'outline-primary',
        'outline-primary',
        'outline-primary',
        'outline-primary',
        'outline-primary',
      ]

      if (this.color[value] === 'outline-primary') {
        this.color[value] = 'primary'
        if (value === 5) {
          this.showDay = true
        }
      }

      this.renderComponent = true
      this.send()
    },
    send() {
      const index = this.color.indexOf('primary')
      let interval = ''
      switch (index) {
        case 0:
          interval = '2'
          break
        case 1:
          interval = '3'
          break
        case 2:
          interval = '5'
          break
        case 3:
          interval = '7'
          break
        case 4:
          interval = '15'
          break
        case 5:
          interval = this.days
          break
        default:
      }
      this.$store.commit('formMedication/UPDATE_FREQUENCY_WEEK', '')
      this.$store.commit('formMedication/UPDATE_FREQUENCY_INTERVAL', interval)
      this.$store.commit('formMedication/UPDATE_FREQUENCY_INTERVAL_COLOR', this.color)
    },
  },
}
</script>
