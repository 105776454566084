<template>

  <div>

    <b-card
      no-body
      class="mb-2 pb-2 pl-2 pr-2"
    >
      <h4 class="mt-2 pt-2">
        {{ this.$store.getters['formMedication/action'] }}
      </h4>
      <div class="mt-2 d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb :items="items" />
      </div>
      <validation-observer ref="frequencyRules">
        <b-form>
          <b-row>

            <b-col
              cols="6"
            >
              <b-form-group
                class="mt-2"
              >
                Frequência

                <b-form-radio
                  v-model="selectedFrequency"
                  class="mt-1"
                  value="Dias contínuos (sem pausas)"
                >
                  Dias contínuos (sem pausas)
                </b-form-radio>

                <b-form-radio
                  v-model="selectedFrequency"
                  class="mt-1"
                  value="Dias alternados (4 em 4 dias, 7 em 7…)"
                >
                  Dias alternados (4 em 4 dias, 7 em 7…)
                </b-form-radio>

                <form-frequency-alternating v-if="selectedFrequency === 'Dias alternados (4 em 4 dias, 7 em 7…)'" />

                <b-form-radio
                  v-model="selectedFrequency"
                  class="mt-1"
                  value="Dias específicos da semana"
                >
                  Dias específicos da semana
                </b-form-radio>

                <form-frequency-week v-if="selectedFrequency === 'Dias específicos da semana'" />

                <template v-if="link === 'favorite'">
                  <b-form-radio
                    v-model="selectedFrequency"
                    class="mt-1"
                    value="Não selecionar uma frequência agora"
                  >
                    Não selecionar uma frequência agora
                  </b-form-radio>
                </template>

              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <b-col
        cols="12"
        class="mt-2"
      >
        <b-button
          type="submit"
          variant="outline-primary"
          @click="cancel"
        >
          Cancelar
        </b-button>
        <b-button
          type="button"
          variant="outline-primary"
          class="ml-2"
          @click="previous"
        >
          Voltar
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          class="ml-2"
          @click="next"
        >
          Continuar
        </b-button>
      </b-col>

    </b-card>

    <form-abstract />

  </div>

</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BCard, BBreadcrumb, BForm, BRow, BCol, BFormGroup, BButton, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import formFrequencyWeek from './Form-medication-frequency-week.vue'
import formFrequencyAlternating from './Form-medication-frequency-alternating.vue'
import formAbstract from './Form-medication-abstract.vue'

export default {
  components: {
    BCard,
    BBreadcrumb,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormRadio,

    formFrequencyWeek,
    formFrequencyAlternating,
    formAbstract,

    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      selectedFrequency: '',
      option: [],
      link: '',
      items: [
        {
          text: 'Medicamento',
          active: true,
        },
        {
          text: 'Horário',
          active: true,
        },
        {
          text: 'Frequência',
          active: false,
        },
        {
          text: 'Duração',
          active: true,
        },
        {
          text: 'Resumo',
          active: true,
        },
      ],
    }
  },

  mounted() {
    this.selectedFrequency = this.$store.getters['formMedication/frequency']
    this.link = this.$store.getters['formMedication/link']
  },

  methods: {
    next() {
      this.$refs.frequencyRules.validate().then(success => {
        if (success) {
          this.$store.commit('formMedication/UPDATE_SHOW_FREQUENCY', true)
          this.$store.commit('formMedication/UPDATE_FREQUENCY', this.selectedFrequency)
          this.$router.push(`${this.link}-duration`)
        }
      })
    },
    cancel() {
      if (this.link === 'patient') {
        this.$store.commit('patient/UPDATE_SHOW_TAB', true)
        this.$store.commit('patient/UPDATE_SHOW_REMINDER_ADD', false)
        this.$store.commit('patient/UPDATE_SHOW_REMINDER_FAVORITE', true)
        this.$router.push(`${this.link}-edit`)
      } else {
        this.$router.push(this.link)
      }
    },
    previous() {
      this.$store.commit('formMedication/UPDATE_SHOW_TIME', false)
      this.$router.push(`${this.link}-time`)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
