<template>
  <div>
    <b-card>
      <h4 class="mt-2">
        Resumo
      </h4>
      <div class="mt-3">
        <strong>Medicamento</strong>
        <div class="mt-2">
          {{ state.selectMedication.name }}
        </div>
        <div>
          <strong>Princípio ativo:</strong> {{ state.selectMedication.active_ingredients }}
        </div>
        <!-- <div>
          <strong>Tipo:</strong> {{ state.selectMedication.medication_format }}
        </div> -->
        <div>
          <strong>Laboratório:</strong> {{ state.selectMedication.laboratory }}
        </div>
        <div>
          <strong>Dosagem:</strong> {{ state.selectMedication.dosage }} {{ state.selectMedication.dosage_type }}
        </div>
      </div>
      <div
        v-if="state.showTime"
        class="mt-3"
      >
        <strong>Horário</strong>
        <div v-if="state.time !== 'Não selecionar um horário agora'">
          <div v-if="state.time === 'Intervalos fixos (8 em 8h, 6 em 6h…)'">
            <div class="mt-2">
              <strong>Intervalos fixos:</strong> {{ state.timeInterval.name }}
            </div>
            <div>
              <strong>Horário:</strong> {{ formatText(state.timeTime) }}
            </div>
            <div>
              <strong>Quantidade:</strong> {{ formatText(state.timeAmount) }}
            </div>
          </div>
          <div v-else>
            <div class="mt-2">
              Intervalos livres
            </div>
            <div>
              <strong>Horário:</strong> {{ formatText(state.timeTime) }}
            </div>
            <div>
              <strong>Quantidade:</strong> {{ formatText(state.timeAmount) }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="mt-2">
            Você não selecionou um horário.
          </div>
        </div>
      </div>

      <div
        v-if="state.showFrequency"
        class="mt-3"
      >
        <strong>Frequência</strong>
        <div v-if="state.frequency !== 'Não selecionar uma frequência agora'">
          <div v-if="state.frequency === 'Dias alternados (4 em 4 dias, 7 em 7…)'">
            <div class="mt-2">
              <strong>Dias alternados:</strong> {{ state.frequencyInterval }} em {{ state.frequencyInterval }} dias
            </div>
          </div>
          <div v-else-if="state.frequency === 'Dias específicos da semana'">
            <div class="mt-2">
              <strong>Dias da semana:</strong> {{ state.frequencyWeekFormated }}
            </div>
          </div>
          <div v-else>
            <div class="mt-2">
              Dias contínuos (sem pausas)
            </div>
          </div>
        </div>
        <div v-else>
          <div class="mt-2">
            Você não selecionou uma frequência.
          </div>
        </div>

        <div
          v-if="state.showDuration"
          class="mt-3"
        >
          <strong>Duração</strong>
          <div v-if="state.duration !== 'Não selecionar uma duração agora'">
            <div class="mt-2">
              {{ state.duration }}
              <template v-if="state.link === 'patient'">
                <template v-if="state.duration === 'Quantidade de dias (início e fim definidos)'">
                  : &nbsp;{{ formatDateBR(state.durationDateInitial) }} a {{ formatDateBR(state.durationDateEnd) }}
                </template>
              </template>
            </div>
          </div>
          <div v-else>
            <div class="mt-2">
              Você não selecionou uma duração.
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },

  data() {
    return {
      state: [],
    }
  },

  beforeMount() {
    this.state = this.$store.getters['formMedication/state']
  },

  methods: {
    formatDateBR(value) {
      return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    formatText(value) {
      const lengthArray = value.length
      let stringWeek = ''

      value.forEach((valueArray, index) => {
        stringWeek += valueArray
        if (index < lengthArray - 2) {
          stringWeek += ', '
        } else if (index === lengthArray - 2) {
          stringWeek += ' e '
        }
      })
      return stringWeek
    },
  },
}
</script>
